import { ReactComponent as PointCoinIcon } from "assets/icon/point_coin_icon.svg"
import { Divider } from "components"
import Typography from "components/common/Typography"
import { EnumMasterBenefitInsuranceType } from "constants/enums/master-benefit-insurance"
import { PackagePricingType } from "constants/enums/package-pricing-type"
import { compose, withHooks, withStores, withTranslation } from "enhancers"
import { TFunction } from "i18next"
import { PageContent } from "layouts"
import { isEmpty } from "lodash"
import { useTranslation } from "react-i18next"
import paths from "routes/paths"
import benefitInsuranceStore from "stores/benefitInsuranceStore"
import previousBenefitInsuranceStore from "stores/previousBenefitInsuranceStore"
import styled from "styled-components"
import Theme from "theme/custom"
import { formatThaiDate, gql } from "utils/helper"
import { BenefitFooter } from "./Footer"
const BenefitMainIcon = Theme.icons.BenefitMainIcon
const MainCard = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  margin: 24px 16px;
  background-color: ${Theme.colors["Primary/Background"]};
  border-radius: 16px;
  padding: 16px;
`

const PointContainer = styled("div")`
  margin: 16px;
`

const PointCard = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 16px;
  height: 84px;
  background-color: ${Theme.colors["Primary/Background"]};
  border-radius: 16px;
  .point-card-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
  }
  .header-32 {
    margin-left: 8px;
    margin-right: 8px;
    font-size: 32px;
  }
`

const CustomDivider = styled(Divider)`
  height: 8px;
`

const HeaderWrapper = styled("div")`
  width: 100%;
`

interface BenefitComponentProps {
  t: TFunction
  year: number | string
  lastDate: string
  point: number
  maxPoint: number
  insurances: string[]
  type: EnumMasterBenefitInsuranceType
  handleClickBack: () => void
  isBenefitEmpty: boolean
}

export const BenefitComponent = (props: BenefitComponentProps) => (
  <>
    <PageContent
      title={props.t(".title", { year: props.year })}
      onBack={props.handleClickBack}
      showActionFooter
      childrenPadding="0px"
      childrenMarginTop="-80px"
    >
      <MainCard>
        <BenefitMainIcon />
        <HeaderWrapper>
          <Typography
            style={{ textAlign: "center", whiteSpace: "normal", marginTop: "16px" }}
            color={Theme.colors["Gray/Primary Text"]}
            variant="h3"
          >
            {props.t(".canChange", { year: props.year })}
          </Typography>
        </HeaderWrapper>
        <br />
        <Typography variant="body1" color={Theme.colors["Text/Secondary Text"]}>
          {props.t(".upToDate", { date: props.lastDate })}
        </Typography>
      </MainCard>
      <CustomDivider />
      <PointContainer>
        <Typography color={Theme.colors["Text/Primary Text"]} variant="Subtitle/16">
          {props.t(".pointOfYear", { year: props.year })}
        </Typography>
        <PointCard>
          <div className="point-card-container">
            <PointCoinIcon style={{ marginRight: "8px" }} />
            <Typography isCurrency variant="Header/32" style={{ marginRight: "8px" }}>
              {props.point}
            </Typography>
            <Typography isCurrency variant="Body/16" color={Theme.colors["Text/Placeholder"]}>
              {`/${props.maxPoint || 0} ${props.t(".point")}`}
            </Typography>
          </div>
        </PointCard>
      </PointContainer>
      {!props.isBenefitEmpty && (
        <>
          <CustomDivider />
          <BenefitFooter insurances={props.insurances} type={props.type} />
        </>
      )}
    </PageContent>
  </>
)

const API = {
  GET_BENEFIT_INSURANCE: gql`
    query GET_BENEFIT_INSURANCE($id: String!) {
      benefitInsurance(id: $id) {
        id
        type
        attendeeId
        attendee {
          enrollmentEndDate
          points
          attendeeGroup {
            point
          }
        }
        type
        benefitInsurancePlans {
          id
          requiredPoint
          insurancePlan {
            id
            masterInsurancePlan {
              id
              nameTh
              nameEn
              premium
              remarkTh
              remarkEn
              description
            }
          }
        }
        benefitInsurancePackages {
          id
          requiredPoint
          usedBudget
          usedCoin
          insurancePackage {
            id
            masterInsurancePackage {
              id
              nameTh
              nameEn
              packagePricingType
            }
          }
        }
        year
        endDate
      }
    }
  `,
}

const enhancer = compose(
  withStores((stores: any) => ({
    selectedBenefitInsurancePlans: stores.benefitInsuranceStore.selectedBenefitInsurancePlans,
    selectedBenefitInsurancePackage: stores.benefitInsuranceStore.selectedBenefitInsurancePackage,
  })),
  withTranslation({ prefix: "pages.main.benefit.index" }),
  withHooks(
    (props: any, hooks: any): Omit<BenefitComponentProps, "t"> => {
      const { useCallback, useQuery, useParams, useMemo, useDataTranslation, useEffect } = hooks
      const { selectedBenefitInsurancePlans, selectedBenefitInsurancePackage } = props

      const { id } = useParams()

      const { data } = useQuery(API.GET_BENEFIT_INSURANCE, { variables: { id }, fetchPolicy: "network-only" })

      const { i18n } = useTranslation()
      const { language } = i18n

      const benefitInsuranceTranslated = useDataTranslation(data?.benefitInsurance)

      const benefitInsurance = useMemo(() => {
        if (benefitInsuranceTranslated) {
          const {
            year,
            endDate,
            attendee,
            type,
            benefitInsurancePlans,
            benefitInsurancePackages,
          } = benefitInsuranceTranslated
          let insurances = []
          let usingPoint = 0
          if (type === EnumMasterBenefitInsuranceType.custom) {
            insurances = benefitInsurancePlans.map((plan: any) => {
              usingPoint += plan.requiredPoint
              return plan.insurancePlan.masterInsurancePlan.name
            })
          } else if (type === EnumMasterBenefitInsuranceType.package) {
            const type = benefitInsurancePackages[0].insurancePackage.masterInsurancePackage.packagePricingType
            if (type === PackagePricingType.SUM_INSURANCES) {
              insurances = benefitInsurancePackages.map((plan: any) => {
                usingPoint += plan.requiredPoint
                return plan.insurancePackage.masterInsurancePackage.name
              })
            } else {
              insurances = benefitInsurancePackages.map((plan: any) => {
                usingPoint += plan.usedBudget
                return plan.insurancePackage.masterInsurancePackage.name
              })
            }
          }
          return {
            year,
            lastDate: formatThaiDate(new Date(attendee.enrollmentEndDate)) || "",
            point: attendee.points - usingPoint,
            maxPoint: attendee.points,
            insurances,
            type,
          }
        }
      }, [benefitInsuranceTranslated])

      const handleClickBack = useCallback(() => {
        paths.homePath().push()
      }, [])

      const isBenefitEmpty = useMemo(() => {
        return isEmpty(benefitInsuranceTranslated?.id)
      }, [benefitInsuranceTranslated])

      useEffect(() => {
        if (!isEmpty(selectedBenefitInsurancePlans)) {
          benefitInsuranceStore.clearSelectedBenefitInsurancePlans()
          previousBenefitInsuranceStore.clearSelectedBenefitInsurancePlans()
        }
      }, [selectedBenefitInsurancePlans])

      useEffect(() => {
        if (!isEmpty(selectedBenefitInsurancePackage)) {
          benefitInsuranceStore.clearSelectedBenefitInsurancePackage()
          previousBenefitInsuranceStore.clearSelectedBenefitInsurancePackage()
        }
      }, [selectedBenefitInsurancePackage])

      return {
        year: benefitInsurance?.year,
        lastDate: benefitInsurance?.lastDate,
        point: benefitInsurance?.point,
        maxPoint: benefitInsurance?.maxPoint,
        insurances: benefitInsurance?.insurances || [],
        type: benefitInsurance?.type,
        handleClickBack,
        isBenefitEmpty,
      }
    },
  ),
)

export const BenefitPage = enhancer(BenefitComponent)
