import { compose, withHooks } from "enhancers"
import "keen-slider/keen-slider.min.css"
import { KeenSliderHooks, KeenSliderInstance, useKeenSlider } from "keen-slider/react"
import React from "react"
import styled from "styled-components"
import Theme from "theme/custom"

const SliderContainer = styled("div")`
  min-height: 100px;
  height: auto;
`

const CarouselRoot = styled("div")`
  .keen-slider:not([data-keen-slider-disabled]) .keen-slider__slide {
    min-height: auto;
  }

  .dots {
    display: flex;
    padding: 10px 0;
    justify-content: center;
    margin-top: 28px;
  }

  .dot {
    border: none;
    width: 10px;
    height: 10px;
    background: ${Theme.colors["Gray/Background "]};
    border-radius: 50%;
    margin: 0 5px;
    padding: 5px;
    cursor: pointer;
  }

  .dot:focus {
    outline: none;
  }

  .dot.active {
    background: ${Theme.colors["Primary/Primary Text"]};
    width: 20px;
    border-radius: 16px;
  }
`
interface CarouselProps {
  sliderRef: (node: HTMLDivElement | null) => void
  instanceRef: React.MutableRefObject<KeenSliderInstance<{}, {}, KeenSliderHooks> | null>
  loaded: boolean
  currentSlide: number
  sliderItems: React.ReactChild[]
  showDots: boolean
  mode?: keyof typeof Mode
  defaultIndex?: number
}

const Mode: any = {
  Mono: {
    slides: {
      origin: "center",
      perView: 1.25,
      spacing: 16,
    },
    breakpoints: undefined,
  },
  Multiple: {
    breakpoints: {
      "(min-width: 700px)": {
        slides: {
          perView: 3.25,
          spacing: null,
        },
      },
      "(max-width: 700px)": {
        slides: {
          perView: 2.25,
          spacing: null,
        },
      },
      "(max-width: 450px)": {
        slides: {
          perView: 1.25,
          spacing: 16,
        },
      },
    },
    slides: undefined,
  },
}

const CarouselComponent = (props: CarouselProps) => (
  <>
    {props.sliderItems.length === 1 && (
      <CarouselRoot>
        <div className="navigation-wrapper">
          <div ref={props.sliderRef} className="keen-slider2">
            {props.sliderItems.map((slider, ind) => (
              <SliderContainer key={`keen-slider${ind}`} className="keen-slider__slide2">
                {slider}
              </SliderContainer>
            ))}
          </div>
        </div>
      </CarouselRoot>
    )}
    {props.sliderItems.length !== 1 && (
      <CarouselRoot>
        <div className="navigation-wrapper">
          <div ref={props.sliderRef} className="keen-slider">
            {props.sliderItems.map((slider, ind) => (
              <SliderContainer key={`keen-slider${ind}`} className="keen-slider__slide">
                {slider}
              </SliderContainer>
            ))}
          </div>
        </div>

        {props.loaded && props.instanceRef.current && props.showDots && (
          <div className="dots">
            {[...Array(props.instanceRef.current.track.details.slides.length).keys()].map((idx) => {
              return (
                <button
                  key={idx}
                  onClick={() => {
                    props.instanceRef.current?.moveToIdx(idx)
                  }}
                  className={"dot" + (props.currentSlide === idx ? " active" : "")}
                ></button>
              )
            })}
          </div>
        )}
      </CarouselRoot>
    )}
  </>
)

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { sliderItems, showDots = false, mode = "Mono", defaultIndex = 0 } = props
    const { useMemo, useState } = hooks
    const [currentSlide, setCurrentSlide] = useState(defaultIndex)
    const [loaded, setLoaded] = useState(false)

    const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
      {
        initial: defaultIndex,
        slideChanged(s) {
          setCurrentSlide(s.track.details.rel)
        },
        created() {
          setLoaded(true)
        },
        mode: "free-snap",
        breakpoints: Mode[mode].breakpoints,
        slides: Mode[mode].slides,
        rtl: false,
      },
      [],
    )

    return {
      sliderItems,
      sliderRef,
      instanceRef,
      loaded,
      currentSlide,
      showDots,
    }
  }),
)

export const Carousel = enhancer(CarouselComponent)
