import { compose, withHooks, withTranslation, withStores } from "enhancers"
import { TFunction, t } from "i18next"
import { PageContent } from "layouts"
import { BenefitFooter } from "./Footer"
import Theme from "theme/custom"
import { Divider, Box, Button, Modal } from "components"
import styled from "styled-components"
import paths from "routes/paths"
import Typography from "components/common/Typography"
import { ReactComponent as PointCoinIcon } from "assets/icon/point_coin_icon.svg"
import { ReactComponent as UserIcon } from "assets/icon/user.svg"
import { ReactComponent as ShieldCheckIcon } from "assets/icon/shield_check-icon.svg"
import { ReactComponent as DocIcon } from "assets/icon/doc-icon.svg"
import { ReactComponent as Sheet } from "assets/icon/sheet.svg"
import { gql, useLazyQuery } from "@apollo/client"
import { EnumMasterBenefitInsuranceType } from "constants/enums/master-benefit-insurance"
import { forceDownload, toCurrency } from "utils/helper"
import { ReactComponent as AlertSignIcon } from "assets/icon/alert_sign-icon.svg"
import T from "components/common/T"

const MainCard = styled("div")`
  display: flex;
  flex-direction: column;
  margin: 16px 16px 0px 16px;
  background-color: ${Theme.colors["Primary/Background"]};
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  padding: 16px 16px 16px 16px;
`

const ViewBox = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 0px 16px 0px 16px;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  background-color: ${Theme.colors["White / White"]};
  padding: 16px 16px 24px 16px;
`

const UserInfo = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border-bottom: 1px solid ${Theme.colors["Gray/Line"]};
  padding-bottom: 24px;
`

const InsuranceDetail = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`

const Benefit = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  border: 1px solid ${Theme.colors["Primary/Primary Text"]};
  border-top: 8px solid ${Theme.colors["Primary/Primary Text"]};
  border-radius: 4px;
`

const InsuranceType = styled(Box)`
  display: flex;
  align-items: center;
  background-color: ${Theme.colors["Primary/Background"]};
  border-bottom: 1px solid ${Theme.colors["Primary/Primary Text"]};
  padding: 8px;
`

const Action = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`

const DetailButton = styled(Button)`
  color: ${Theme.colors["Green/Primary Text"]};
  border: none;
  font-size: 14px;
  font-weight: 700;
  padding: 2px 8px 2px 8px;
  height: 26px;
`

const BenefitInsurance = styled(Box)`
  padding: 8px 16px 16px 16px;
`

const InsuranceItems = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
`

const PointBox = styled(Box)`
  display: flex;
  flex-direction: row;
`

const Point = styled(Box)`
  display: flex;
  justify-content: flex-end;
  width: 50px;
`

interface ViewBenefitComponentProps {
  t: TFunction
  handleClickBack: () => void
  name: string
  age: number
  handleViewDetail: () => void
  year: string
  type: string
  benefitInsurancePlans: [any]
  benefitInsurancePackages: [any]
  download: () => void
  hasFile?: boolean
}

const ViewBenefitComponent = (props: ViewBenefitComponentProps) => (
  <PageContent
    title={props.t(".title", { year: props.year })}
    onBack={props.handleClickBack}
    showActionFooter
    // actionFooter={<BenefitFooter insurances={props.insurances} />}
    childrenPadding="0px"
    childrenMarginTop="-80px"
    bgColor={Theme.colors["Gray/Background "]}
  >
    <MainCard>
      <Typography color={Theme.colors["Text/Primary Text"]} variant="h3">
        {props.t(".selected", { year: props.year })}
      </Typography>
      <Box mt={2}>
        <Typography variant="Body/14" color="Gray/Secondary Text">
          {props.t(".view", { year: props.year })}
        </Typography>
        {props.hasFile && (
          <>
            <div style={{ height: "16px" }} />
            <Button
              variant="outlined"
              style={{ display: "flex", justifyContent: "center", width: "100%", height: "auto" }}
              onClick={() => props.download()}
            >
              <Sheet style={{ marginRight: "4px" }} />
              {props.t(".insuranceDetailSheet")}
            </Button>
          </>
        )}
      </Box>
    </MainCard>
    <ViewBox>
      <UserInfo>
        <Box>
          <Typography variant="Header/16" color={Theme.colors["Text/Primary Text"]}>
            {props.t(".ownInsurance")}
          </Typography>
          <Box mt={1}>
            <Typography variant="Body/14" color={Theme.colors["Primary/Primary Text"]}>
              {(props.name || "") + " " + props.t(".years", { year: props.age })}
            </Typography>
          </Box>
        </Box>
        <UserIcon fill={Theme.colors["Primary/Primary Text"]} style={{ marginTop: "1px" }} />
      </UserInfo>
      <InsuranceDetail>
        <Typography color="Gray/Secondary Text" variant="h5">
          {props.t(".insuranceDetail")}
        </Typography>
        <Benefit>
          <InsuranceType>
            <ShieldCheckIcon style={{ marginRight: "8px" }} fill={Theme.colors["Primary/Primary Text"]} />
            <Typography variant="h3" color={Theme.colors["Primary/Primary Text"]}>
              {props.type === EnumMasterBenefitInsuranceType.custom ? props.t(".custom") : props.t(".package")}
            </Typography>
          </InsuranceType>
          <BenefitInsurance>
            {props.benefitInsurancePlans?.map((benefitInsurancePlan: any) => (
              <InsuranceItems>
                <Typography variant="subtitle2" color={Theme.colors["Text/Primary Text"]}>
                  {benefitInsurancePlan.insurancePlan.masterInsurancePlan.name}
                </Typography>
                <PointBox>
                  <PointCoinIcon width={16} height={16} />
                  <Point>
                    <Typography variant="subtitle2" color="Gray/Secondary Text">
                      {toCurrency(benefitInsurancePlan.requiredPoint, { minimumFractionDigits: 0 })}
                    </Typography>
                  </Point>
                </PointBox>
              </InsuranceItems>
            ))}
            {props.benefitInsurancePackages?.map((benefitInsurancePackage: any) => (
              <InsuranceItems>
                <Typography variant="subtitle2" color={Theme.colors["Text/Primary Text"]}>
                  {benefitInsurancePackage.insurancePackage.masterInsurancePackage.name}
                </Typography>
                <PointBox>
                  <PointCoinIcon width={16} height={16} />
                  <Point>
                    <Typography variant="subtitle2" color="Gray/Secondary Text">
                      {toCurrency(benefitInsurancePackage.requiredPoint, { minimumFractionDigits: 0 })}
                    </Typography>
                  </Point>
                </PointBox>
              </InsuranceItems>
            ))}
          </BenefitInsurance>
        </Benefit>
      </InsuranceDetail>
      <Action>
        <Box></Box>
        <DetailButton variant="outlined" onClick={props.handleViewDetail}>
          <DocIcon width={16} height={16} fill={Theme.colors["Primary/Primary Text"]} style={{ marginRight: "4px" }} />
          <Typography variant="h6" color={Theme.colors["Primary/Primary Text"]}>
            {props.t(".viewDetail")}
          </Typography>
        </DetailButton>
      </Action>
    </ViewBox>
  </PageContent>
)

const API = {
  GET_BENEFIT_INSURANCE: gql`
    query GET_BENEFIT_INSURANCE($id: String!) {
      benefitInsurance(id: $id) {
        id
        attendeeId
        type
        benefitInsurancePlans {
          id
          requiredPoint
          insurancePlan {
            id
            masterInsurancePlan {
              id
              nameTh
              nameEn
              premium
              remarkTh
              remarkEn
              description
            }
          }
        }
        benefitInsurancePackages {
          id
          requiredPoint
          insurancePackage {
            id
            masterInsurancePackage {
              id
              nameTh
              nameEn
            }
          }
        }
        year
        hasFile
      }
    }
  `,
  GET_FILE_DOWNLOAD: gql`
    query GET_FILE_DOWNLOAD($id: String!) {
      downloadFile(id: $id) {
        file {
          url
          fileName
        }
      }
    }
  `,
}

const enhancer = compose(
  withStores((stores: any) => ({
    currentUser: stores.userStore.currentUser,
  })),
  withTranslation({ prefix: "pages.main.benefit.view" }),
  withHooks(
    (props: any, hooks: any): Omit<ViewBenefitComponentProps, "t"> => {
      const { currentUser } = props
      const { useCallback, useParams, useQuery, useMemo, useDataTranslation } = hooks
      const { id } = useParams()
      const [loadGreeting, { data: dataFile }] = useLazyQuery(API.GET_FILE_DOWNLOAD, {
        variables: { id },
        onCompleted: async (data: any) => {
          if (dataFile) {
            let blob = await fetch(dataFile.downloadFile.file.url)
              .then((r) => r.blob())
              .then((blobFile) => new File([blobFile], dataFile.downloadFile.file.fileName, { type: blobFile.type }))
            const url = URL.createObjectURL(blob)
            forceDownload(url, dataFile.downloadFile.file.fileName)
          }
        },
        onError: (error: any) => {
          Modal.alert({
            title: " ",
            children: (
              <>
                <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mb={4} mt={10}>
                  <AlertSignIcon />
                  <Box mt={8}>
                    <T variant="Header/16" color="Gray/Primary Text2">
                      {"client.pages.main.benefit.detail.errorDownloadFile"}
                    </T>
                  </Box>
                  <Box mt={4}>
                    <T variant="Body/14" color="Gray/Secondary Text">
                      {"client.pages.main.benefit.detail.upload"}
                    </T>
                  </Box>
                </Box>
              </>
            ),
            onClose: handleCloseModal,
            headerCloseButton: true,
            okButtonLabel: (
              <Button style={{ border: "none" }}>
                <T>{`client.pages.main.benefit.detail.ok`}</T>
              </Button>
            ),
          })
        },
        fetchPolicy: "network-only",
      })
      const download = useCallback(async () => {
        await loadGreeting()
      }, [loadGreeting])
      const handleCloseModal = (data: any) => {
        Modal.closeAlertModal()
      }
      const { loading, data, error } = useQuery(API.GET_BENEFIT_INSURANCE, {
        variables: { id },
        fetchPolicy: "network-only",
      })

      const benefitInsurance = useDataTranslation(data?.benefitInsurance)

      const getAgeFromBirthdate = useCallback((date: any) => {
        const today = new Date()
        const startDate = new Date(date)
        const todayCount = Math.floor(today.getTime() / (3600 * 24 * 1000))
        const startDateCount = Math.floor(startDate.getTime() / (3600 * 24 * 1000))
        const daysDiff = todayCount - startDateCount
        return Math.floor(daysDiff / 365)
      }, [])

      const handleClickBack = useCallback(() => {
        paths.homePath().push()
      }, [])

      const handleViewDetail = useCallback(() => {
        paths.viewDetailBenefitPath(id).push()
      }, [id])

      const age = getAgeFromBirthdate(currentUser?.employee?.birthDate)

      return {
        download,
        handleClickBack,
        name: currentUser?.employee?.firstNameTh + " " + currentUser?.employee?.lastNameTh,
        age,
        handleViewDetail,
        year: benefitInsurance?.year,
        type: benefitInsurance?.type,
        benefitInsurancePlans: benefitInsurance?.benefitInsurancePlans,
        benefitInsurancePackages: benefitInsurance?.benefitInsurancePackages,
        hasFile: benefitInsurance?.hasFile || false,
      }
    },
  ),
)

export const ViewBenefitPage = enhancer(ViewBenefitComponent)
